var common = {

	tiles: function () {
		$('[tile]').each(function() {
			var img = $(this).attr('tile'),
				height;
			$(this).css('background','url(' + img + ') 0 0 no-repeat');
			$(this).css('-webkit-background-size','cover');
			$(this).css('-moz-background-size','cover');
			$(this).css('-o-background-size','cover');
			$(this).css('background-size','cover');
			$(this).addClass('tile--bg-loaded');
			/*if ($(this).hasClass('tile--double-width')) {
				//height = Math.floor($(this).width() / 2);
				height = $(this).width() / 2.0;
			} else {
				//height = Math.ceil($(this).width());
				height = $(this).width();
			}
			if ($(this).hasClass('tile--double-height')) {
				height *=  2.0 ;
			}
			$(this).height(height);*/
		});

		/*window.addEventListener('resize', function (e, a) {
			$('[tile]').each(function() {
				var height;
				if ($(this).hasClass('tile--double-width')) {
					//height = Math.floor($(this).width() / 2);
					height = $(this).width() / 2.0;
				} else {
					//height = Math.ceil($(this).width());
					height = $(this).width();
				}
				if ($(this).hasClass('tile--double-height')) {
					height *=  2.0 ;
				}
				$(this).height(height);
			});
		});*/

	},

	tooltip: function () {
		$('[data-toggle="tooltip"]').tooltip({
			html: true
		});
	},

	swipe: function (selector) {
		var width = $('body').width();
		$(selector).each(function () {
			$(this).width(width);
		});
	},

	gallery: function () {
		var target = $('.gallery__container'),
			toggleSlick = function () {
				if ($(window).width() < 751) {
					if(!target.hasClass('slick-initialized')) {
						target.slick({
							accessibility: false,
							autoplay: true,
							arrows: false,
							mobileFirst: true,
							slidesToShow: 1,
							slidesToScroll: 1
						});
					}
					
				} else {
					if(target.hasClass('slick-initialized')) {
						target.slick('unslick');
					}
				}
			};
		toggleSlick();
		$(window).resize(toggleSlick);
	},

	openOverlay: function (sheet) {
		var objSheet = $('#overlay-' + sheet);
		if($('body').hasClass('modal-open') && !objSheet.hasClass('overlay-open')) {
			$('.overlay-open').removeClass('overlay-open');
			$('.overlay-active').removeClass('overlay-active');
			$('body').removeClass('modal-open');
		}
		if (objSheet.length > 0) {
			objSheet.toggleClass('overlay-open');
			if($('body').hasClass('modal-open')) {
				$('.overlay-active').removeClass('overlay-active');
			}
			$('[data-overlay='+sheet+']').addClass('overlay-active');
			$('body').toggleClass('modal-open');
			if(objSheet.find('.overlay-close').length === 0) {
				var closeBtn = $('<div>').addClass('overlay-close').click(function () {
					objSheet.removeClass('overlay-open');
				});
				objSheet.find('.overlay-menu').append(closeBtn);
			}
		}
	},

	closeOverlays: function () {
		if($('body').hasClass('modal-open')) {
			$('.overlay-open').removeClass('overlay-open');
			$('body').removeClass('modal-open');
			$('.overlay-active').removeClass('overlay-active');
		}
	},

	toggleClasses: function () {
		var _elems = $('*[data-toggle-class]'),
			_selectors = [];
		if (_elems.length > 0) {
			_elems.each(function () {
				var _class = $(this).data('toggle-class'),
					_selector = $(this).data('toggle-class-on-parent') ? $(this).parent() : $(this),
					_isExclusive = $(this).data('toggle-exclusive') ? true : false;
				_selectors.push({
					selector: _selector,
					cls: _class
				});
				$(this).click(function () {
					_selector.toggleClass(_class);
					_selector.blur();
					_selector.unbind('mouseenter mouseleave');
					_selector.find('*[data-toggle-class-helper]').click(function () {
						_selector.removeClass(_class);
					});
					if (_isExclusive) {
						$.each(_selectors, function (i, e) {
							if(e.selector != _selector) {
								$(e.selector).removeClass(e.cls);
							}
						});
					}
					//additionally if the focus needs to be set to another attribute that
					//can be added via markup as well
					var _focusTo = $(this).data('toggle-focus-to');
					if($(_focusTo !== undefined)) {
						$('#' + _focusTo).trigger('focus');
					}
				});
			});
		}
	},
	
	loadCover: function (selector) {
		var covers = $(selector + '[data-cover]');
		if (covers.length > 0) {
			covers.each(function () {
				$(this).css('background','url(' + $(this).data('cover') + ') no-repeat');
				$(this).css('-webkit-background-size','cover');
				$(this).css('-moz-background-size','cover');
				$(this).css('-o-background-size','cover');
				$(this).css('background-size','cover');
				$(this).addClass('cover-loaded');
			});
		}
	},

	handleExternalLinks: function () {
		var container = $('body'),
			baseUrl = window.location.host,
			strRegex =  'a[href^="http:"]:not([href*="' + baseUrl + '"]), ' +
						'a[href^="https:"]:not([href*="' + baseUrl + '"])';
		// Open all external links and PDF in new window
		container.find(strRegex).on('click', function() {
			$(this).attr('target', '_blank');
		});
	},

	callPhone: function (selector) {
		$(selector).click(function (e) {
			e.preventDefault;
			window.location = 'callto:' + $(this).text();
		})
	},

	productSlider: function () {
		if ($('.page--product').length > 0) {
			//main picture
			$('.product__images--additional').magnificPopup({
				type:'image',
				delegate: 'a',
				gallery: {
					enabled:true
				}
			});

		}
	},

    // Inputs not hidden on focus
    focusInputs: function(){

        var elements = 'input[type=text], input[type=password], select, textarea';
        var labelHeight = 32;
        var navbarOffset = 0;

        $(window).scroll(function(){
            navbarOffset = $('.navigation').offset().top;
        });

        $('.main').on('focus',elements,function(e){
            var $nav = $('.navigation');
            var $this = $(this);
            var thisOffset = $this.offset().top;
            var siblingLabel = $this.siblings('label').first().offset().top;
            var windowBottom = $(window).scrollTop()  + $(window).height();

            if((navbarOffset + $nav.height() + labelHeight) > siblingLabel || (thisOffset + $this.height()) > windowBottom)
                $('html, body').scrollTop($this.offset().top - $nav.height() - labelHeight);
        });
    },

	matchStaticHeights: function(){
        var formations = $('.grid-col--formation');
    	var productsMain = $('.main__products .product--main');
        var options = {
            property: 'height',
            remove: false,
            byRow: true
        };
		if(productsMain.length > 0){
            productsMain.matchHeight(options);
		}
        if(formations.length > 0){
            formations.matchHeight(options);
        }
	},

	initMap: function(){

		var map,marker,pos;

		pos = {lat: 46.541913, lng: 6.631454};

		// Create a map object and specify the DOM element for display.
		map = new google.maps.Map(document.getElementById('contact-map'), {
			center: pos,
			scrollwheel: false,
			zoom: 17,
			disableDefaultUI: false
		});

		// Create a marker and set its position.
		marker = new google.maps.Marker({
			map: map,
			position: pos,
			title: 'La Perle Rare',
			icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAjCAYAAACOysqWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzcyQTY2MjczRjUyMTFFNjk5QkY5Q0Q0NEUyRDQ3NjUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzcyQTY2MjgzRjUyMTFFNjk5QkY5Q0Q0NEUyRDQ3NjUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDNzJBNjYyNTNGNTIxMUU2OTlCRjlDRDQ0RTJENDc2NSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDNzJBNjYyNjNGNTIxMUU2OTlCRjlDRDQ0RTJENDc2NSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuaME94AAAIGSURBVHjarJbNK0RRGMbPfGAtC1OWhAUlNTbKRyn/AUtriXwnmjJkhabMzkKRDUqWslGUfJUpCs1GxmIkqTHUYIznrXfqut0559y596lfM3PO+77PmXvOPed4JlqjQqI60As6QT2o5PZncAcOwRa4L1TAX6C9FsyBHuCx6A8wHWAWbIMQiJsDvRbJw+CaR+4Rank49ppzpQbLIAJKhX2Vce5SIYN+MCqca4xr/TNoBFHhnqhWg9FgEfhcNPDlHxUZBEG3cF9UM+jlpajSBwjzYFr4+6dGXg+9B+2KoG/QBU4NbRdgHxyBEkluG/2DGoXBpql4XqfcJ1M1GZQrgq6K7CNVkEFaEVRVZB/pnQwSiqA+GonV6LhPpgQZnCuCaFM7AE2GtiZuCyhyz2gV7WqMpJmfd9JgqqM9Ly+3B82EgI3iVHOfDL5473dbdE585feidY25sCN6RzaMm90vGOBPp6IagyBnPg8uwZoLBmtcy/JEmwZvDoq/ginZkfkCZhwYhNhEeuivglgRxWOcq7xVZMFQfpI0leNFktUxIB1rbMVG0ZI8serwSpImQUqjeMo8sboGSc03PGzYo2wZkFbAraT/hmNEsQbfPHmFRIvhx4mB4Bv0jkX7DvcJpwakcdM1Ja17zdQ1eATzht8L4MlNA8E35zgT0U3y2zDIgBHejjO6SX8CDADKWmnjT6grrgAAAABJRU5ErkJggg=='
		});

	},

	bootstrapSelect: function() {
        $('.selectpicker').selectpicker();
    },

	categorySidebar: function() {
		var sidebar = $('.main__categories.category--side');
		sidebar.find('.is-dropdown').on('click', function() {
            console.log('category__menu click');
			$(this).closest('li').toggleClass('is-open');
		});
	},

	categoryFilters: function() {
        var viewport = $( document ).width();
        var pageSearch = $('.product-search');
        var pageCatalogue = $('.page--catalogue-detail');

        var page = 1;
        var currentIteration = 0;
        var filters = '';
        var queryString = '';
        var isLoading = true;
        var isAjaxError = false;
        var noMoreData = false;
        var itemsContainer = $('.main__products');
        var spinner = '<img class="spinner" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAADh0lEQVRYhbXYa4hVVRQH8N/cGUwNC4yygTBLoYdOBfby1QNjLCqiQj8UoQglFfSh6I0KkWn0UArsQ0VETmEvkGJ60ROsMOtDMzVFZZiBFRkllWXl9GGdY2due8/MZe784XLuWfv1P2v/19rrnJa+vj4N4mTch9n4Fg/i/kYnqUetwf5T8TrOQBumFKRurPTpxNvYgVdxynAmbmuQyNU4KGG/FWPxF+5Ea2E/AnMwDx8NNnFLZmvacQM68CXW42A8imMbJA/PYNFgHVIeaccW8TSEq5f57ylT+KpY6C1MSLRPGZxnWiO3VEiUaMUPQqgf17XtxTXC9R9m1pmAiY0SOSnTd1yx0DyhiRfwME4TooSb8HvduD/Fdr6LbvTgCSH8/UhtzfYMkR3FdTfWZPp8gNMLQtPQi9VYgcU4pug3AwtERG0n7ZE3M4vkFq9HD67ALFyJbZiZ6Hcobi5vqh6ZiKNxO34TT9eBr7EOXcMkksJxGXvpIW0YI7Lj0gqxIcOtQXyO4xP2z8o/NazCVQZ6ZyEuaCKRFeivs/2Me6tElmYGL2kikedwLl4TXvgbW8W27ycyJjN4XBOJECHeKfSyRaSBTkWirMlHSc4+ErRigzi5D8ArIhsfUhNZ8Yu6Ad14YBSILMPldba5WFMTNcWJhbEX83G+SN3NxsUZ+8Iyof1Tuf4xCgRK7MvY+2s4XCiY8MxmPCsv4pFgY8beVROx3FHXcCmuGwUij+EhA3PKy7ithosygxaMApF+ERybsUecR+dhd00c0ynsaTKJC/GOOG1nifTwftlYEzVFChuaSOISbBJJbLLIJ2dhepXISjxu4L69iKebSGQlWups41Wq/5rIF0tEXTlf1J9ni6T2nYioy0ZIZGrGflSVSIlv8AaW40AhokmiqOkyskOwvs4t0ZsiUmJ2ZtDyYS46XRDfiidFauhJ9NuFe8qbVM06LbPA5OI6FtcK4e0SYi/VP1NExvjK/SIhzt7id6Tw0GqV+jhF5BNRO9TjF1HhrxfhV2KxOMg24o4KiRKtou6YW8yRROpNb5Jwa/XdZp/B35N3ijzRjcMS7dvkBUtm8u9xKtaKWuEpoZs58qJrF+RTJODTwUiQfwnfiesT9udxQsL+K+6uXKsH5l7cNRSRRj9LPIKfEva14ivAOpExu8V2vIRz8N5QE+e+BgyGGcXCZ+JHId5V/l+lN4R/AdUhuhHYs+oyAAAAAElFTkSuQmCC"/>';


        var _fillProductCallback = function(_cb) {
            if(_cb) _cb();
		};

		var fillProductTemplate =  function(itr, product, _callback) {
            var template = $('#product-template').html();
			if($(template).length > 0) {
                template = template.replace("%href%", product.href);
                template = template.replace("%image%", product.image);
                template = template.replace("%title%", product.title);
                template = template.replace("%description%", product.description);
                template = template.replace("%price%", product.price);
                template = template.replace("%price_old%", product.price_old);
                if ( itr && (itr % 3 === 0) ) {
                    itemsContainer.append('<div class="clearfix hidden-xs"></div>');
                }
                itemsContainer.append(template);

			}
			_callback();
        };

		var ajaxSuccess = function(results) {
			if(results.length > 0) {
                $.each(results, function (i, result) {
                    _fillProductCallback(
                    	fillProductTemplate( currentIteration, result, function() {
                            // console.log(currentIteration);
                    		currentIteration += 1;
                    	})
					);
                });
                page += 1;
            } else {
				noMoreData = true;
			}
            $('.spinner').remove();
            isLoading = false;
		};

		var ajaxError = function(jqXHR, status, error) {
            console.log("AJAX error: " + error);
            isAjaxError = true;
            $('.spinner').remove();
            isLoading = false;
		};

		var loadPage = function(resetPageCount) {
			if(resetPageCount) page = 1;

            isLoading = true;

			queryString = $('.filters .form').attr('action') + "&page=" + page + filters;
            if(viewport < 768) {
                queryString = $('.dropdown--filters .form').attr('action') + "&page=" + page + filters;
			}
            console.log("url = " + queryString);

            return $.ajax({
				url: queryString,
				dataType: "JSON"
			});
        };

		var filtersFormSubmit = function(filtersForm) {
			var searchField = $(filtersForm).find('input[name="search"]');

            filtersForm.find('select').change(function() {
                currentIteration = 0;
                isAjaxError = false;
                noMoreData = false;
                itemsContainer.find('.product').remove();
                itemsContainer.find('.clearfix').remove();
                filtersForm.submit();
            });

            searchField.on('input', function() {
                currentIteration = 0;
                isAjaxError = false;
                noMoreData = false;
                itemsContainer.find('.product').remove();
                itemsContainer.find('.clearfix').remove();
                filtersForm.submit();
            });

            filtersForm.on("submit", function (e) {
                e.preventDefault();
                filters = '';
                var selectFields = $(this).find('select');
                $.each(selectFields, function(i, select) {
                    if($(select).val().length > 0)
                        filters += '&' + $(select).attr('name') + '=' + $(select).val();
                });

                if($(searchField).val().length > 0)
                    filters +=  '&' + $(searchField).attr('name') + '=' + $(searchField).val();
                $.when(loadPage(true)).then(ajaxSuccess, ajaxError);
            });
		};

		if(pageCatalogue.length > 0 || pageSearch.length > 0) {
			itemsContainer.append(spinner);
            $.when(loadPage(false)).then(ajaxSuccess, ajaxError);

			$(window).scroll(function() {
				if(isAjaxError || isLoading || noMoreData) return; // no more ajax calls if last page

				var lastItem = $('.main__products .product:last-of-type'),
                    docViewTop, docViewBottom, elemTop, elemBottom;

				if(lastItem.length > 0) {
                    docViewTop = $(window).scrollTop();
                    docViewBottom = docViewTop + $(window).height();
                    elemTop = $(lastItem).offset().top;
                    elemBottom = elemTop + $(lastItem).height();

                    if( (elemBottom <= docViewBottom) && (elemTop >= docViewTop) ) {
                        itemsContainer.append(spinner);
                        $.when(loadPage(false)).then(ajaxSuccess, ajaxError);
                    }
				}
			});

            filtersFormSubmit($('.filters .form'));
            filtersFormSubmit($('.dropdown--filters .form'));
		}
	}

};