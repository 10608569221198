var contact = {
	validateForm: function() {

		var frLang = {
			required: "Ce champ est obligatoire.",
			remote: "Veuillez corriger ce champ.",
			email: "Veuillez fournir une adresse électronique valide.",
			url: "Veuillez fournir une adresse URL valide.",
			date: "Veuillez fournir une date valide.",
			dateISO: "Veuillez fournir une date valide (ISO).",
			number: "Veuillez fournir un numéro valide.",
			digits: "Veuillez fournir seulement des chiffres.",
			creditcard: "Veuillez fournir un numéro de carte de crédit valide.",
			equalTo: "Veuillez fournir encore la même valeur.",
			extension: "Veuillez fournir une valeur avec une extension valide.",
			maxlength: $.validator.format("Veuillez fournir au plus {0} caractères."),
			minlength: $.validator.format("Veuillez fournir au moins {0} caractères."),
			rangelength: $.validator.format("Veuillez fournir une valeur qui contient entre {0} et {1} caractères."),
			range: $.validator.format("Veuillez fournir une valeur entre {0} et {1}."),
			max: $.validator.format("Veuillez fournir une valeur inférieure ou égale à {0}."),
			min: $.validator.format("Veuillez fournir une valeur supérieure ou égale à {0}."),
			maxWords: $.validator.format("Veuillez fournir au plus {0} mots."),
			minWords: $.validator.format("Veuillez fournir au moins {0} mots."),
			rangeWords: $.validator.format("Veuillez fournir entre {0} et {1} mots."),
			letterswithbasicpunc: "Veuillez fournir seulement des lettres et des signes de ponctuation.",
			alphanumeric: "Veuillez fournir seulement des lettres, nombres, espaces et soulignages.",
			lettersonly: "Veuillez fournir seulement des lettres.",
			nowhitespace: "Veuillez ne pas inscrire d'espaces blancs.",
			integer: "Veuillez fournir un nombre non décimal qui est positif ou négatif.",
			dateITA: "Veuillez fournir une date valide.",
			time: "Veuillez fournir une heure valide entre 00:00 et 23:59.",
			strippedminlength: $.validator.format("Veuillez fournir au moins {0} caractères."),
			email2: "Veuillez fournir une adresse électronique valide.",
			url2: "Veuillez fournir une adresse URL valide.",
		},
		deLang = {
			required: "Dieses Feld ist ein Pflichtfeld.",
			maxlength: $.validator.format("Geben Sie bitte maximal {0} Zeichen ein."),
			minlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
			rangelength: $.validator.format("Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein."),
			email: "Geben Sie bitte eine gültige E-Mail Adresse ein.",
			url: "Geben Sie bitte eine gültige URL ein.",
			date: "Bitte geben Sie ein gültiges Datum ein.",
			number: "Geben Sie bitte eine Nummer ein.",
			digits: "Geben Sie bitte nur Ziffern ein.",
			equalTo: "Bitte denselben Wert wiederholen.",
			range: $.validator.format("Geben Sie bitte einen Wert zwischen {0} und {1} ein."),
			max: $.validator.format("Geben Sie bitte einen Wert kleiner oder gleich {0} ein."),
			min: $.validator.format("Geben Sie bitte einen Wert größer oder gleich {0} ein."),
			creditcard: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein."
		};

		switch($('.form--contact').data('language')) {
			case 'fr':
				$.extend($.validator.messages, frLang);
				break;
			case 'de':
				$.extend($.validator.messages, deLang);
				break;
		}

		$('.form--contact').validate({
			submitHandler: function(form) {
				form.submit();
			},
			lang: 'en',
			onkeyup: true,
			errorElement: 'em',
			errorPlacement: function(error, element) {
				var _container = $(element).parent();
				_container.addClass('input--error');
				error.appendTo(_container.find('label'));
			},
			highlight: function(element) {
				$(element).parent().addClass('input--error');
			},
			unhighlight: function(element) {
				 $(element).parent().removeClass('input--error');
			},
			rules: {
				name: {
					required: true,
				},
				surname: {
					required: true,
				},
				email: {
					required: true,
					email: true
				},
				phone: {
					required: true,
				},
				enquiry: {
					required: true,
				}
			}
		});
	}
};