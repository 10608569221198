var footer = {

    mobileSearch: function () {
        $('#searchMobile input[name=\'search\']').parent().find('button').on('click', function() {
            var url = $('base').attr('href') + 'index.php?route=product/search',
                value = $('#searchMobile input[name=\'search\']').val();
            if (value) {
                url += '&search=' + encodeURIComponent(value);
            }
            window.location = url;
        });
    }
};