var header = {

	sticky: function () {

		var stickyTop = 5;

		if($('.main').length>0){
			stickyTop = $('.main').offset().top - $('#headerBar').height();
		}

		$('#headerBar').affix({
			offset: {
				top: stickyTop
			}
		});
	},

	bg: function () {
		$(".header--cover").interactive_bg({
			strength: 25,
			scale: 1.05,
			animationSpeed: "100ms",
			contain: true,
			wrapContent: false
		});
	},

	video: function () {
		if ($("#headerVideo").length > 0) {
			$('#headerVideo').vide({
				mp4: '/themes/idessin/assets/dist/images/video/1.mp4',
				poster: '/themes/idessin/assets/dist/images/video/1.jpg'
			});
		}
	},

	scrollDown: function () {
		if ($('#scrollDown').length > 0) {
			$('#scrollDown').click(function () {
				$("html, body").animate({
					scrollTop: $(window).height() - 64 //navbar height is 64
				}, 600);
			});
		}
	}
};