/*DOM needs to be rdy*/
(function ($) {
	$( document ).ready(function() {
        var has_touch = 'ontouchstart' in document.documentElement;
        //common.swipe('.gallery__item');
        header.bg();
        header.sticky();

        $('.tile-container').bricks();
        common.loadCover('.entry__image');

        $('*[data-overlay]').click(function (e, a) {
            $(this).addClass('overlay-active');
            e.preventDefault();
            common.openOverlay(e.currentTarget.dataset.overlay);
        });

        //resizing the browser can cause problems on opened overlays, so close them immediately
        $(window).resize(function () {
            if (!has_touch) {
                common.closeOverlays();
            }
        });

        common.toggleClasses();
        common.tiles();
        common.gallery();
        common.tooltip();
        //the external links have to be opened in a new window
        common.handleExternalLinks();

        if ($('.page--contact').length > 0) {
            contact.validateForm();
        }

        footer.mobileSearch();

        //Bootstrap select
        common.bootstrapSelect();

        //Inputs focus
        common.focusInputs();

        //Match static heights
        common.matchStaticHeights();

        // Categories sidebar dropdown functionality
        common.categorySidebar();

        // init infinite scroll
        common.categoryFilters();

        //Google maps
        if($('body').hasClass('page--contact'))
            common.initMap();
	});
})(jQuery);
